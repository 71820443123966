'use client'

import type { PropsWithChildren } from 'react'

import { disableFragmentWarnings } from '@apollo/client'
// eslint-disable-next-line import/no-unresolved -- @apollo/experimental-nextjs-app-support provides the exports setting which is not supported by eslint-plugin-import
import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support'

/**
 * We need to disable fragment warnings due to the way the PreloadQuery is leveraged.
 *
 * See: https://github.com/apollographql/apollo-client-nextjs/issues/328#issuecomment-2208407530 for more info.
 */
disableFragmentWarnings()

import { initApolloClient } from './client'

export const ApolloProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ApolloNextAppProvider makeClient={initApolloClient}>
      {children}
    </ApolloNextAppProvider>
  )
}
