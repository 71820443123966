import { useEffect, useState } from 'react'
import tagManager from 'react-gtm-module'

export interface GTMProviderProps {
  /**
   * Google Tag Manager id.
   */
  id?: string
}

/**
 * Add Google Tag Manager.
 */
export const GTMProvider: React.FC<GTMProviderProps> = ({ id }) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (id && typeof window === 'object' && !initialized) {
      tagManager.initialize({
        gtmId: id,
      })
      setInitialized(true)
    }
  }, [id, initialized])

  return null
}
