import Script from 'next/script'

export interface HubSpotProviderProps {
  /**
   * ID of the HubSpot portal the tracking should be associated with.
   */
  portalId?: string | null
}

export const HubSpotProvider: React.FC<HubSpotProviderProps> = ({
  portalId,
}) => {
  if (!portalId) return null

  return (
    <Script
      id="hs-script-loader"
      src={`//js.hs-scripts.com/${portalId}.js`}
      type="text/javascript"
      async
      defer
    />
  )
}
