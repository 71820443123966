export {
  useBackgroundQuery,
  useFragment,
  // eslint-disable-next-line import/no-unresolved -- @apollo/experimental-nextjs-app-support provides the exports setting which is not supported by eslint-plugin-import
} from '@apollo/experimental-nextjs-app-support/ssr'
export {
  useQuery,
  useLazyQuery,
  useLoadableQuery,
  useReadQuery,
  useSuspenseQuery,
  useMutation,
} from '@apollo/client'
export type { QueryRef } from '@apollo/client'
