import { createContext, useContext } from 'react'

import type { ClientConfig } from '@features/common.graphql'

export const ConfigContext = createContext<ClientConfig>({} as ClientConfig)

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}
