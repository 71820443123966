'use client'

import { ConfigContext } from '@features/common.hooks/useConfig'

import type { ClientConfig } from '@features/common.graphql'

export interface ConfigProviderProps {
  /**
   * Children of the provider.
   */
  children: React.ReactNode
  /**
   * Client config.
   */
  config: ClientConfig
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({
  children,
  config,
}) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}
