import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useModal } from '@circlefin/modal-router/useModal/useModal'
import { useOneTrust } from '@circlefin/one-trust/react'
import { useAnalytics } from '@features/analytics.hooks/useAnalytics'

import { DatadogProvider } from './DatadogProvider/DatadogProvider'
import { GTMProvider } from './GTMProvider/GTMProvider'
import { HubSpotProvider } from './HubSpotProvider/HubSpotProvider'

import type { DatadogProviderProps } from './DatadogProvider/DatadogProvider'
import type { GTMProviderProps } from './GTMProvider/GTMProvider'
import type { HubSpotProviderProps } from './HubSpotProvider/HubSpotProvider'
import type { RouteEventData } from '@circlefin/modal-router'

/**
 * Props for the InternalAnalyticsProvider component.
 */
export interface InternalAnalyticsProviderProps {
  /** Flag that indicates if analytics is enabled or not. */
  enabled: boolean
  /** Configuration for third party scripts. */
  config?: {
    /** Datadog configuration. */
    datadog?: DatadogProviderProps
    /** Google Tag Manager configuration. */
    gtm?: GTMProviderProps
    /** HubSpot configuration. */
    hubspot?: HubSpotProviderProps
  }
}

/**
 * InternalAnalyticsProvider component that manages analytics tracking and third-party integrations.
 * @param props - The component props.
 * @returns The rendered component.
 */
export const InternalAnalyticsProvider: React.FC<
  InternalAnalyticsProviderProps
> = ({ enabled, config }) => {
  const initialPageViewTracked = useRef(false)
  const { hasPerformance, hasTargeting } = useOneTrust()
  const modal = useModal()
  const { trackMouseEvent, trackPageView, setCurrentModal } = useAnalytics()

  const analyticsEnabled = useMemo(() => {
    return enabled === true && hasPerformance && hasTargeting
  }, [enabled, hasPerformance, hasTargeting])

  /**
   * Tracks modal view and updates analytics context.
   * @param event - The route event data.
   */
  const trackModal = useCallback(
    (event?: RouteEventData) => {
      if (event) {
        setCurrentModal(event)
      }

      if (!event?.nextRoute) {
        // track page view again
        void trackPageView({
          name: window.location.pathname,
          path: window.location.pathname,
        })
      }
    },
    [setCurrentModal, trackPageView],
  )

  useEffect(() => {
    if (!enabled) return

    modal.events.on('onRouteChange', trackModal)
    return () => modal.events.off('onRouteChange', trackModal)
  }, [enabled, modal.events, trackModal])

  useEffect(() => {
    if (!enabled) return

    if (!initialPageViewTracked.current) {
      // Track the initially mounted/rendered page only once.
      void trackPageView({
        name: window.location.pathname,
        path: window.location.pathname,
      })
      initialPageViewTracked.current = true
    }
  }, [enabled, trackPageView, initialPageViewTracked])

  useEffect(() => {
    if (!enabled) return

    const onClick = (event: MouseEvent) => {
      void trackMouseEvent(event)
    }

    window.addEventListener('click', onClick)
    return () => window.removeEventListener('click', onClick)
  }, [enabled, trackMouseEvent])

  return (
    <>
      {analyticsEnabled && (
        <>
          {config?.gtm?.id && <GTMProvider {...config.gtm} />}
          {config?.datadog && <DatadogProvider {...config.datadog} />}
          {config?.hubspot?.portalId && <HubSpotProvider {...config.hubspot} />}
        </>
      )}
    </>
  )
}
