/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type ModalState = {
  nextRoute?: InputMaybe<Scalars['String']['input']>;
  prevRoute?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  trackEvent: Scalars['Boolean']['output'];
  trackPageView: Scalars['Boolean']['output'];
};


export type MutationTrackEventArgs = {
  input: TrackEventInput;
};


export type MutationTrackPageViewArgs = {
  input: TrackPageViewInput;
};

export type PageProperties = {
  path: Scalars['String']['input'];
  referrer?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ScreenProperties = {
  availableHeight: Scalars['Int']['input'];
  availableWidth: Scalars['Int']['input'];
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type TrackEventInput = {
  modal?: InputMaybe<ModalState>;
  name: Scalars['String']['input'];
  page: PageProperties;
  properties: Scalars['JSON']['input'];
  screen: ScreenProperties;
};

export type TrackPageViewInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  modal?: InputMaybe<ModalState>;
  name: Scalars['String']['input'];
  page: PageProperties;
  path: Scalars['String']['input'];
  screen: ScreenProperties;
};

export type TrackEventMutationVariables = Exact<{
  input: TrackEventInput;
}>;


export type TrackEventMutation = { __typename?: 'Mutation', trackEvent: boolean };

export type TrackPageViewMutationVariables = Exact<{
  input: TrackPageViewInput;
}>;


export type TrackPageViewMutation = { __typename?: 'Mutation', trackPageView: boolean };


export const TrackEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"TrackEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackEventInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"trackEvent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<TrackEventMutation, TrackEventMutationVariables>;
export const TrackPageViewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"TrackPageView"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackPageViewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"trackPageView"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<TrackPageViewMutation, TrackPageViewMutationVariables>;