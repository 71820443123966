/**
 * Returns screen properties that should be tracked for each event.
 */
export const getScreenProperties = () => {
  return {
    availableHeight: window.screen.availHeight,
    availableWidth: window.screen.availWidth,
    height: window.screen.height,
    width: window.screen.width,
  }
}

/**
 * Returns page properties that should be tracked for each event.
 */
export const getPageProperties = () => {
  return {
    path: window.location.pathname,
    referrer: window.document.referrer,
    search: window.location.search,
    url: window.location.href,
    title: window.document.title,
  }
}

/**
 * Represents a mapped mouse event for tracking purposes.
 */
export type TrackedInteraction =
  | {
      /** Indicates that a button was clicked. */
      event: 'Button Clicked'
      properties: {
        /** The text content of the button. */
        text: string | null
        /** Custom tracking identifier for the button, if specified. */
        dataAttribute?: string
        /** The aria-label of the button, if specified. */
        ariaLabel?: string
      }
    }
  | {
      event: 'Link Clicked'
      properties: {
        /** The text content of the link. */
        text: string | null
        /** The URL that the link points to. */
        href?: string
        /** Custom tracking identifier for the button, if specified. */
        dataAttribute?: string
        /** The aria-label of the button, if specified. */
        ariaLabel?: string
        /** The target attribute of the link, specifying where to open the linked document. */
        target?: string
      }
    }

/**
 * Extracts tracking data from a mouse event by analyzing the event's composition path.
 * @param event - The MouseEvent to analyze.
 * @returns An array of TrackedInteraction objects.
 */
export const extractTrackingDataFromMouseEvent = (
  event: MouseEvent,
): TrackedInteraction[] => {
  // The user might click on an child element that's inside an element we want to track.
  // That's why we check the bubble path, to see if it includes an element we want to track
  return event
    .composedPath()
    .reduce<TrackedInteraction[]>((interactions, bubbledTarget) => {
      if (bubbledTarget instanceof Element) {
        switch (bubbledTarget.nodeName) {
          case 'BUTTON':
            interactions.push({
              event: 'Button Clicked',
              properties: {
                text: bubbledTarget.textContent,
                dataAttribute:
                  /* c8 ignore next 2 */
                  bubbledTarget.getAttribute('data-tracking') || undefined,
                ariaLabel:
                  bubbledTarget.getAttribute('aria-label') || undefined,
              },
            })
            break
          case 'A':
            interactions.push({
              event: 'Link Clicked',
              properties: {
                text: bubbledTarget.textContent,
                href: bubbledTarget.getAttribute('href') ?? undefined,
                dataAttribute:
                  /* c8 ignore next 2 */
                  bubbledTarget.getAttribute('data-tracking') || undefined,
                target: bubbledTarget.getAttribute('target') || undefined,
              },
            })
            break
        }
      }
      return interactions
    }, [])
}
