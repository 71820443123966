import introspection from '@shared/graphql/generated/introspection.json'

interface Types {
  name: string | null
  possibleTypes: PossibleTypes[] | null
}

interface PossibleTypes {
  kind: string
  name: string
  ofType: PossibleTypes | null
}

type PossibleTypesAcc = Record<string, string[]>

/**
 * Our GraphQL client needs to understand the polymorphic relationship between our GraphQL interfaces
 * and the GraphQL types that implement them. So, this function translates a GraphQL introspection
 * JSON into a possibleTypes configuration object.
 * See: {@link https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces}.
 */
export const possibleTypes = introspection.__schema.types.reduce(
  (acc: PossibleTypesAcc, supertype: Types) => {
    if (supertype.possibleTypes != null && supertype.name != null) {
      acc[supertype.name] = supertype.possibleTypes.map(
        (subtype) => subtype.name,
      )
    }
    return acc
  },
  {},
)
