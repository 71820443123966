import { possibleTypes } from './possibleTypes'

import type { InMemoryCacheConfig } from '@apollo/client'

/**
 * Apollo In Memory Cache Config.
 */
export const cacheConfig: InMemoryCacheConfig = {
  possibleTypes,
  addTypename: true,
}
