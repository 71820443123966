import { createContext, useContext } from 'react'

import type { FeatureFlags } from '@features/flags.graphql'

/**
 * Default empty context object.
 */
export const defaultContextValue: FeatureFlags = {} as FeatureFlags

/**
 * Context for storing feature flags.
 */
export const FeatureFlagsContext =
  createContext<FeatureFlags>(defaultContextValue)

/**
 * Custom hook to access feature flags from the FeatureFlagsContext.
 * @throws If used outside of a FeatureFlagsProvider.
 * @returns A getter method to access feature flags.
 */
export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagsContext)
  if (context === defaultContextValue) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider',
    )
  }
  return context
}
