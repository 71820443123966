'use client'

import { FeatureFlagsDocument } from '@features/flags.graphql'
import { FeatureFlagsContext } from '@features/flags.hooks/useFeatureFlags'
import { useQuery } from '@shared/apollo'

export interface FeatureFlagsProviderProps {
  /**
   * Children of the provider.
   */
  children: React.ReactNode
}

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({
  children,
}) => {
  const { data } = useQuery(FeatureFlagsDocument, {
    /**
     * Re-fetch feature flags every 60 seconds.
     */
    pollInterval: 60000,
  })

  // We can return null since we will prefetch the feature flag query on the server
  if (!data) return null

  return (
    <FeatureFlagsContext.Provider value={data.featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
