import type { CookieRecordSet } from '@circlefin/one-trust/core/cookies/types'

export const PerformanceCookies: CookieRecordSet[] = [
  {
    domain: '.circle.com',
    cookies: [
      {
        name: '__analytics__',
      },
      {
        name: 'hubspotutk',
      },
      {
        name: '__hssc',
      },
      {
        name: '__hssrc',
      },
      {
        name: '__hstc',
      },
      {
        name: 'ajs_anonymous_id',
      },
      {
        name: 'ajs_user_id',
      },
      {
        name: '_gcl_au',
      },
      {
        name: '_ga',
      },
      {
        name: '_gid',
      },
      {
        name: '_ga_5VHHH38YPK',
      },
      {
        name: '_gat_UA-44999976-22',
      },
    ],
  },
  {
    domain: 'console.circle.com',
    cookies: [
      {
        name: 'ln_or',
      },
      {
        name: '_dd_s',
        sameSite: 'strict',
      },
    ],
  },
  {
    domain: 'console-staging.circle.com',
    cookies: [
      {
        name: '_dd_s',
        sameSite: 'strict',
      },
    ],
  },
  {
    domain: 'localhost',
    cookies: [
      {
        name: 'hubspotutk',
      },
      {
        name: '__hssc',
      },
      {
        name: '__analytics__',
      },
      {
        name: '__hssrc',
      },
      {
        name: '__hstc',
      },
      {
        name: '_dd_s',
        sameSite: 'strict',
      },
    ],
  },
  {
    // default domain cookies
    cookies: [
      {
        name: '_dd_s',
        sameSite: 'strict',
      },
      {
        name: 'ln_or',
      },
    ],
  },
]
